module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"eff64e6e9d05a1323a0c80f6256508ce"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"undefined","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"countryDetection":true,"cookiePolicyInOtherWindow":true,"consentOnContinuedBrowsing":false,"lang":"en","siteId":1831941,"cookiePolicyId":23496832,"banner":{"applyStyles":false,"rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","position":"float-bottom-right","textColor":"white","backgroundColor":"#000001","zIndex":9999999,"cookiePolicyLinkCaption":"Learn more.","content":"We're using cookies. <a href=\"https://www.iubenda.com/privacy-policy/23496832/cookie-policy\" target=\"_blank\" rel=\"noopener\" class=\"cookie-link\">Learn more.</a>","innerHtmlCloseBtn":"<button type=\"button\" class=\"iubenda-cs-close-btn\" tabindex=\"0\" role=\"button\" aria-pressed=\"false\">OK</button>"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
